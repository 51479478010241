<template>
  <div>
    <div class="app-container">
      <div class="tizer">
        <div class="item"><span>25 лет </span>на рынке технического текстиля</div>
        <div class="item"><span>Современное </span>высокотехнологическое оборудование</div>
        <div class="item"><span>Квалифицированный </span>состав специалистов</div>
        <div class="item"><span>Отлаженные </span>связи с поставщиками сырья</div>
        <div class="item"><span>Широкий </span>ассортимент выпускаемых тканей и текстильных изделий</div>
        <div class="item"><span>Продукция </span>проходит ежегодную экспертизу и сертификацию</div>
      </div>
    </div>
    <div id="production"></div>
    <div class="production">
      <div class="card-wrap app-container">
        <div class="card-block">
          <v-card
            class="mx-auto card"
            max-width="270"
            v-for="(prod, n) in getProdItem()" 
            :key="n"
            @click="push(prod.id)"
          >
            <v-img
              :src="prod.path + prod.img"
              height="250px"
              class="img"
              gradient="rgba(0,0,0,.35), rgba(0,0,0,.55)"
            >
              <div class="imgDesc">
                <v-icon left color="#FFFFFF">mdi-arrow-right-bold-box</v-icon> перейти
              </div>
            </v-img>

            <div class="txt">
              {{prod.prod}}
            </div>

          </v-card>
        </div>
      </div>
      <v-img 
        class="prod-banner" 
        src="img/banner_2.jpg"
        gradient="rgba(0,0,0,.35), rgba(0,0,0,.55)"
      ></v-img>
      <h2>Наша продукция</h2>
    </div>
    <div class="desc app-container">
      <div class="desc-item">
        <h2>Постоянная модернизация и внедрение нового оборудования</h2>
        <p>один из залогов успешного развития предприятия. Производственный потенциал ткацкого оборудования позволяет вырабатывать широкий ассортимент тканей различного назначения и выпускать в месяц до полумиллиона квадратных метров. </p>
        <p>Основной стратегией ООО «ТрейдВит» является. Увеличение объёмов производства, освоение новых видов продукции  и расширение рынков сбыта, удовлетворение потребностей покупателей в качественных технических тканях и текстильных изделиях, повышение их потребительских свойств, качественных и эргономических показателей выпускаемой продукции. </p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    components: {},
    data: () => ({
      path: {

      }
    }),
    computed: {
      
    },
    methods: {
      ...mapGetters(['getProdItem']),
      push(id) {
        localStorage.setItem('prodID', id)
        this.$router.push('/prodpage')
        document.querySelector('#topBanner').classList.add('views')
      }
    },
    mounted() {
      
    }
  }
</script>

<style lang="scss" scoped>
  $text_color: #595959;
  .tizer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 15px;
    .item {
      max-width: 450px;
      text-transform: uppercase;/*  */
      padding: 25px;
      text-align: center;
      box-shadow: 2px 2px 5px 2px grey;
      border-radius: 3px;
      span {
        font-weight: bold;
        font-size: 1.2em;
      }
    }
  }
  .production {
    height: 350px;
    margin-top: 250px;
    position: relative;
    .card-wrap {
      position: absolute;
      width: 100%;
      top: -150px;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      .card-block {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 10px;
        .card {
          cursor: pointer;
          transition: all .3s;
          .img {
            position: relative;
            .imgDesc {
              color: #FFFFFF;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-transform: uppercase;
              padding: 3px 9px;
              transition: all .3s;
              opacity: 0;
            }
          }
          .txt {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 15px;
            text-transform: uppercase;
            text-align: center;
            color: $text_color;
          }
          &:hover {
            transform: scale(1.1);
            .imgDesc {
              opacity: 1;
            }
          }
        }
      }
    }
    .prod-banner {
      width: 100%;
      height: 100%;
    }
    h2 {
      color: #ffffff;
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 17%;
      transform: translate(-50%, -17%);
      text-transform: uppercase;
    }
  }
  .desc {
    margin: 55px auto;
    max-width: 900px;
    .desc-item {
      margin: 35px 0;
      h2 {
        font-size: 2.1em;
        font-weight: 300;
        /* color: #8b0000; */
        line-height: 1;
        margin-bottom: 15px;
      }
      p {
        color: $text_color;
        font-weight: 300;
      }
    }
  }
  @media only screen and (max-width: 1205px) {
    .img {
      display: none;
    }
    .card-wrap {
      top: 50px !important;
    }
    .card-block {
      grid-template-columns: 1fr !important;
    }
    
    .production {
      height: 390px;
      margin-top: 55px; 
      h2 {
        top: 5%;
        transform: translate(-50%, -5%);
        z-index: 0;
      }
    }
    .tizer {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
  @media only screen and (max-width: 451px) {
    .production {
      h2 {
        font-size: 1.1em;
      }
    }
  }
</style>
